import { Paper } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { Alert, Modal, Spinner, Table } from "react-bootstrap";
import { baseUrl } from "../../../../services/config";
import { useAlert } from "react-alert";
import ReactToPrint from "react-to-print";
import { upperCase } from "lodash";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { useSelector } from "react-redux";
import { PremissionChecker } from "../../../../services/PremissionChecker";
const StudentHistory = () => {
  const user = useSelector((state) => state.user.user);
  const [admission_id, setadmission_id] = useState("");
  const [loading, setLoading] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [isallfields, setisallfields] = useState(false);
  const [studentData, setStudentData] = useState({});
  const [show, setShow] = useState(false);

  const [sessionData, setSessionData] = useState([]);
  const [historyData, setHistoryData] = useState([]);
  const [year_id, setYear_id] = useState("");

  const [addHistoryModal, setAddHistoryModal] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [date, setDate] = useState("");

  const [editHistoryModal, setEditHistoryModal] = useState(false);
  const [editHistoryID, setEditHistoryID] = useState([]);
  const [editTitle, setEditTitle] = useState("");
  const [editDescription, setEditDescription] = useState("");
  const [editDate, setEditDate] = useState("");

  const [deleteHistoryModal, setDeleteHistoryModal] = useState(false);
  const [deleteableHistory, setDeleteableHistory] = useState("");

  //for print
  const [printloading, setprintloading] = React.useState(false);
  const [text, setText] = React.useState("old boring text");
  const componentRef = useRef(null);
  const onBeforeGetContentResolve = React.useRef(null);

  let authtoken = localStorage.getItem("userToken");

  const [unitName, setUnitName] = useState("");
  const [icon, setIcon] = useState([]);
  const alert = useAlert();

  useEffect(() => {
    sessions();
    getSettingData();
  }, []);

  const getSettingData = async () => {
    await fetch(`${baseUrl}/get-settings`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.payload);
        setUnitName(upperCase(data.payload.settings.unit_name));
        setIcon(data.payload.settings.logo_file);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const sessions = () => {
    fetch(`${baseUrl}/session`, {
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setSessionData(data.payload);
        data.payload.forEach((session, index) => {
          if (session.active_academic_year == "1") {
            setYear_id(session.id);
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getStudentHistory = () => {
    let data = {
      year_id: year_id,
      id: admission_id,
    };
    setLoading(true);
    fetch(`${baseUrl}/show-student-history`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);
        if (data.metadata.success) {
          setStudentData(data.payload?.student);
          setHistoryData(data.payload?.student_history);
          setShow(true);
        } else {
          Object.values(data.metadata.message).map((msg) => {
            alert.error(msg);
          });
          setStudentData([]);
          setHistoryData([]);
          setShow(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setShow(false);
      });
  };

  // Handle Add, Update and Delete Student History
  const addHistoryHandler = async () => {
    if (title == "" || date == "" || description == "") {
      setisallfields(true);
      return;
    }
    setAddLoading(true);
    setisallfields(false);

    let data = {
      title: title,
      date: date,
      description: description,
      student_id: studentData.id,
      session_id: year_id,
      user_id: user?.id,
    };

    await fetch(`${baseUrl}/student-history`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data, "testing");
        if (data.metadata) {
          setAddLoading(false);
          if (data.metadata.success) {
            alert.success("Student History Successfully Added");
            setStudentData([]);
            setHistoryData([]);
            setTitle("");
            setDate("");
            setDescription("");
            getStudentHistory();
            setAddHistoryModal(false);
          } else if (!data.metadata.success) {
            Object.values(data.metadata.message).map((msg) => {
              alert.error(msg);
            });
            setAddHistoryModal(false);
          }
        } else {
          alert.show("Something Went Wrong");
          setAddHistoryModal(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setAddLoading(false);
      });
  };

  const getEditHistory = async (id) => {
    await fetch(`${baseUrl}/student-history/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => {
        res.json().then((data) => {
          setEditHistoryID(data?.payload?.id);
          setEditTitle(data.payload?.title);
          setEditDescription(data.payload?.description);
          setEditDate(data.payload?.date);
        });
      })
      .catch((err) => {
        alert.error(err);
        setLoading(false);
      });
  };

  const updateHistoryHandler = async () => {
    if (editTitle == "" || editDate == "" || editDescription == "") {
      setisallfields(true);
      return;
    }
    setEditLoading(true);
    setisallfields(false);

    let data = {
      title: editTitle,
      date: editDate,
      description: editDescription,
      student_id: studentData.id,
      session_id: year_id,
      user_id: user?.id,
    };

    await fetch(`${baseUrl}/student-history/${editHistoryID}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) =>
        res.json().then((data) => {
          if (data.metadata.success) {
            setEditLoading(false);
            setEditHistoryModal(false);
            alert.success("Student History successfully Updated");
            setStudentData([]);
            setHistoryData([]);
            setEditTitle("");
            setEditDate("");
            setEditDescription("");
            getStudentHistory();
          } else if (!data.metadata.success) {
            alert.error(data.metadata.message);
            setEditLoading(false);
            setEditHistoryModal(false);
          } else {
            alert.show("Something Went Wrong");
            setEditLoading(false);
            setEditHistoryModal(false);
          }
        })
      )
      .catch((err) => {
        setEditLoading(false);
        setEditHistoryModal(false);
        console.log(err);
      });
  };

  const deleteHistoryHandle = async () => {
    setDeleteLoading(true);

    await fetch(`${baseUrl}/student-history/${deleteableHistory}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata) {
          if (data.metadata.success) {
            alert.success(data.metadata.message);
            setStudentData([]);
            setHistoryData([]);
            getStudentHistory();
            setShow(false);
            setDeleteLoading(false);
            setDeleteHistoryModal(false);
          } else if (!data.metadata.success) {
            alert.error(data.metadata.message);
            setDeleteLoading(false);
            setDeleteHistoryModal(false);
            setShow(false);
          }
        } else {
          alert.show("Something Went Wrong");
          setDeleteLoading(false);
          setDeleteHistoryModal(false);
          setShow(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setDeleteLoading(false);
        setDeleteHistoryModal(false);
      });
  };
  const getMonthName = (date) => {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const d = new Date(date);

    return `${monthNames[d.getMonth()]}`;
  };

  const yearSplice = (item) => {
    return item.slice(0, 4);
  };

  //for print
  const handleBeforePrint = React.useCallback(() => {
    console.log("`onBeforePrint` called");
  }, []);

  const handleOnBeforeGetContent = React.useCallback(() => {
    console.log("`onBeforeGetContent` called");
    setprintloading(true);
    setText("Loading new text...");

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setprintloading(false);
        setText("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setprintloading, setText]);

  const handleAfterPrint = React.useCallback(() => {
    console.log("`onAfterPrint` called");
  }, []);

  const currentDate = new Date();
  const displayDate = currentDate.toLocaleDateString("en-US", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
  });

  return (
    <>
      <Paper
        style={{ marginTop: "10px", marginBottom: "10px", padding: "15px" }}
      >
        <div className="row p-5">
          <div className="col-md-3">
            <b>
              <label>Select Session</label>{" "}
              <span style={{ color: "red", fontSize: 12 }}>*</span>
            </b>
            <select
              style={{ height: 40 }}
              className="form-select"
              name="year_id"
              value={year_id}
              aria-label="Default select example"
              onChange={(e) => setYear_id(e.target.value)}
            >
              <option value="">Select Session</option>
              {sessionData &&
                sessionData.map((item) => (
                  <option value={item.id}>{item.year}</option>
                ))}
            </select>
          </div>
          <div className="col-md-4">
            <b>
              <label>Enter Student Admission / Registeration No.</label>{" "}
              <span style={{ color: "red", fontSize: 12 }}>*</span>
            </b>
            <input
              className="form-control"
              onChange={(e) => setadmission_id(e.target.value)}
              placeholder="Admission Id"
            />
          </div>
          <div className="col-md-4 d-flex mt-9">
            <button
              className="btn btn-primary mx-2"
              onClick={getStudentHistory}
            >
              Search
            </button>
            {historyData?.length > 0 ? (
              <>
                <ReactToPrint
                  documentTitle={`Student Fee Details`}
                  pageStyle="auto"
                  onAfterPrint={handleAfterPrint}
                  onBeforeGetContent={handleOnBeforeGetContent}
                  onBeforePrint={handleBeforePrint}
                  removeAfterPrint={true}
                  trigger={() => (
                    <button
                      // onClick={() => console.log("dfsdfsdfd")}
                      disabled={printloading ? true : false}
                      className="btn btn-primary w-90px mx-2"
                    >
                      {printloading ? <span>Printing..</span> : "Print"}
                    </button>
                  )}
                  content={() => componentRef.current}
                />

                <button
                  className="btn btn-primary mx-2"
                  onClick={() => setAddHistoryModal(true)}
                >
                  Add History
                </button>
              </>
            ) : null}
          </div>
        </div>
        <br />

        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spinner class="text-center" variant="info" animation="border" />
          </div>
        ) : (
          <>
            {show ? (
              <>
                <div className="container">
                  <div className="row mb-1">
                    <div className="col-md-6 d-flex">
                      <h5 className="text-primary">Admission No:-</h5>
                      <h6>&nbsp; &nbsp; {studentData?.admission_id}</h6>
                    </div>
                    <div className="col-md-6 d-flex">
                      <h5 className="text-primary">Class:-</h5>
                      <h6>&nbsp; &nbsp; {studentData?.student_class?.name}</h6>
                    </div>
                  </div>
                  <div className="row mb-1">
                    <div className="col-md-6 d-flex">
                      <h5 className="text-primary">Student Name:-</h5>
                      <h6>&nbsp; &nbsp; {studentData?.name}</h6>
                    </div>
                    <div className="col-md-6 d-flex">
                      <h5 className="text-primary">Section:-</h5>
                      <h6>&nbsp; &nbsp; {studentData?.global_section?.name}</h6>
                    </div>
                  </div>
                  <div className="row mb-1">
                    <div className="col-md-6 d-flex">
                      <h5 className="text-primary">Father Name:-</h5>
                      <h6>&nbsp; &nbsp; {studentData?.father_name}</h6>
                    </div>
                    <div className="col-md-6 d-flex">
                      <h5 className="text-primary">Session:-</h5>
                      <h6>&nbsp; &nbsp; {studentData?.session?.year}</h6>
                    </div>
                  </div>
                </div>
                <br />
                <div id="tableboot">
                  <Table responsive>
                    <thead>
                      <tr id="tbl" className="text-center text-black">
                        <th>SrNo.</th>
                        <th>Title</th>
                        <th>Description</th>
                        <th>Date</th>
                        <th>Action</th>
                        <th>Edit</th>
                        <th>Delete</th>
                      </tr>
                    </thead>

                    <tbody>
                      {historyData &&
                        historyData.map((item, index) => (
                          <tr id="tbl" key={index}>
                            <td className="text-center text-black">
                              {index + 1}
                            </td>
                            <td className="text-start text-black">
                              {item?.title}
                            </td>
                            <td className="text-start text-black">
                              {item?.description}
                            </td>
                            <td className="text-center text-black">
                              {item?.date}
                            </td>
                            <td className="text-center text-black">
                              {item?.user?.first_name +
                                " " +
                                item?.user?.last_name}
                            </td>

                            {PremissionChecker(
                              user,
                              "student.edit_student_history"
                            ) &&
                            user?.id === 1 &&
                            item?.student_history_type_id !== 16 ? (
                              <td className="text-center text-black">
                                <a
                                  key={index}
                                  className="btn btn-icon btn-light btn-hover-primary btn-sm"
                                  onClick={() => {
                                    getEditHistory(item?.id);
                                    setEditHistoryID(item?.id);
                                    setEditHistoryModal(true);
                                  }}
                                >
                                  <span className="svg-icon svg-icon-md svg-icon-primary">
                                    <SVG
                                      src={toAbsoluteUrl(
                                        "/media/svg/icons/Communication/Write.svg"
                                      )}
                                    />
                                  </span>
                                </a>
                              </td>
                            ) : item?.student_history_type_id === 16 ? (
                              <td className="text-center text-black">
                                <a
                                  key={index}
                                  className="btn btn-icon btn-light btn-hover-primary btn-sm"
                                  onClick={() => {
                                    getEditHistory(item?.id);
                                    setEditHistoryID(item?.id);
                                    setEditHistoryModal(true);
                                  }}
                                >
                                  <span className="svg-icon svg-icon-md svg-icon-primary">
                                    <SVG
                                      src={toAbsoluteUrl(
                                        "/media/svg/icons/Communication/Write.svg"
                                      )}
                                    />
                                  </span>
                                </a>
                              </td>
                            ) : (
                              <td></td>
                            )}

                            {PremissionChecker(
                              user,
                              "student.delete_student_history"
                            ) &&
                            user?.id === 1 &&
                            item?.student_history_type_id !== 16 ? (
                              <td className="text-center text-black">
                                <a
                                  className={
                                    "btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                                  }
                                  onClick={() => {
                                    setDeleteableHistory(item?.id);
                                    setDeleteHistoryModal(true);
                                  }}
                                >
                                  <span className="svg-icon svg-icon-md svg-icon-danger">
                                    <SVG
                                      src={toAbsoluteUrl(
                                        "/media/svg/icons/Communication/Delete-user.svg"
                                      )}
                                    />
                                  </span>
                                </a>
                              </td>
                            ) : item?.student_history_type_id === 16 ? (
                              <td className="text-center text-black">
                                <a
                                  className={
                                    "btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                                  }
                                  onClick={() => {
                                    setDeleteableHistory(item?.id);
                                    setDeleteHistoryModal(true);
                                  }}
                                >
                                  <span className="svg-icon svg-icon-md svg-icon-danger">
                                    <SVG
                                      src={toAbsoluteUrl(
                                        "/media/svg/icons/Communication/Delete-user.svg"
                                      )}
                                    />
                                  </span>
                                </a>
                              </td>
                            ) : (
                              <td></td>
                            )}
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </div>
              </>
            ) : null}
          </>
        )}
      </Paper>

      <Modal show={addHistoryModal} size="lg">
        <Modal.Header className="modal_header_style" closeButton>
          <Modal.Title
            className="Modal_Heading_font"
            id="contained-modal-title-vcenter"
          >
            Add Student History
          </Modal.Title>
        </Modal.Header>

        {addLoading ? (
          <div style={{ padding: "20px" }}>
            {" "}
            <b style={{ color: "green" }}>
              {" "}
              Student History Adding Please Wait...{" "}
              <span className="spinner-borderd"></span>
            </b>
          </div>
        ) : (
          <Modal.Body>
            <div className="row">
              <div className="col-md-6 mb-4">
                <label>
                  <b>Enter Title</b>
                  <span style={{ color: "red", fontSize: 12 }}>*</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  onChange={(e) => setTitle(e.target.value)}
                  placeholder="Enter History Title"
                />
              </div>

              <div className="col-md-6 mb-4">
                <label>
                  <b>Select Date</b>
                  <span style={{ color: "red", fontSize: 12 }}>*</span>
                </label>
                <input
                  className="form-control"
                  onChange={(e) => setDate(e.target.value)}
                  type="date"
                />
              </div>

              <div className="col-md-12">
                <label>
                  <b>Enter Description</b>
                  <span style={{ color: "red", fontSize: 12 }}>*</span>
                </label>

                <input
                  className="form-control"
                  onChange={(e) => setDescription(e.target.value)}
                  placeholder="Enter Description"
                />
              </div>
            </div>

            <br />
            <br />
            {isallfields ? (
              <>
                <Alert
                  variant="danger"
                  onClose={() => setisallfields(false)}
                  dismissible
                >
                  <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
                  <p>Please fill or select all fields</p>
                </Alert>
              </>
            ) : null}
          </Modal.Body>
        )}

        <Modal.Footer>
          <button
            className="btn btn-primary"
            onClick={addHistoryHandler}
            disabled={addLoading ? true : false}
          >
            Add History
          </button>
          <button
            className="btn btn-danger btn_width"
            onClick={() => {
              setAddHistoryModal(false);
              setisallfields(false);
            }}
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>

      {/* Update Student History */}
      <Modal show={editHistoryModal} size="lg">
        <Modal.Header className="modal_header_style" closeButton>
          <Modal.Title
            className="Modal_Heading_font"
            id="contained-modal-title-vcenter"
          >
            Update Student History
          </Modal.Title>
        </Modal.Header>

        {editLoading ? (
          <div style={{ padding: "20px" }}>
            {" "}
            <b style={{ color: "green" }}>
              {" "}
              Student History Updating Please Wait...{" "}
              <span className="spinner-borderd"></span>
            </b>
          </div>
        ) : (
          <Modal.Body>
            <div className="row">
              <div className="col-md-6 mb-4">
                <label>
                  <b>Enter Title</b>
                  <span style={{ color: "red", fontSize: 12 }}>*</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  value={editTitle}
                  onChange={(e) => setEditTitle(e.target.value)}
                  placeholder="Enter History Title"
                />
              </div>

              <div className="col-md-6 mb-4">
                <label>
                  <b>Select Date</b>
                  <span style={{ color: "red", fontSize: 12 }}>*</span>
                </label>
                <input
                  className="form-control"
                  value={editDate}
                  onChange={(e) => setEditDate(e.target.value)}
                  type="date"
                />
              </div>

              <div className="col-md-12">
                <label>
                  <b>Enter Description</b>
                  <span style={{ color: "red", fontSize: 12 }}>*</span>
                </label>

                <input
                  className="form-control"
                  value={editDescription}
                  onChange={(e) => setEditDescription(e.target.value)}
                  placeholder="Enter Description"
                />
              </div>
            </div>

            <br />
            <br />
            {isallfields ? (
              <>
                <Alert
                  variant="danger"
                  onClose={() => setisallfields(false)}
                  dismissible
                >
                  <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
                  <p>Please fill or select all fields</p>
                </Alert>
              </>
            ) : null}
          </Modal.Body>
        )}

        <Modal.Footer>
          <button
            className="btn btn-primary"
            onClick={updateHistoryHandler}
            disabled={editLoading ? true : false}
          >
            Update
          </button>
          <button
            className="btn btn-danger btn_width"
            onClick={() => {
              setEditHistoryModal(false);
              setisallfields(false);
            }}
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>

      {/* Delete Student History */}
      <Modal show={deleteHistoryModal}>
        <Modal.Header className="modal_header_style" closeButton>
          <Modal.Title
            className="Modal_Heading_font"
            id="contained-modal-title-vcenter"
          >
            Are you sure want to delete this student history?
          </Modal.Title>
        </Modal.Header>
        {deleteLoading ? (
          <div style={{ padding: "20px" }}>
            {" "}
            <b style={{ color: "green" }}>
              {" "}
              Student History Deleting Please Wait{" "}
              <span className="spinner-borderd"></span>
            </b>
          </div>
        ) : (
          <Modal.Footer>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <button onClick={deleteHistoryHandle} className="btn button_blue">
                Delete
              </button>
              <button
                style={{ marginLeft: "10px" }}
                onClick={() => setDeleteHistoryModal(false)}
                className="btn btn-danger btn_width"
              >
                Cancel
              </button>
            </div>
          </Modal.Footer>
        )}
      </Modal>

      {/* //for print */}
      <div
        ref={componentRef}
        id="printSection"
        style={{ visibility: "hidden" }}
        className="tablePrint"
      >
        <>
          {show ? (
            <>
              <div className="p-3 d-flex">
                <img style={{ height: 120, width: 120 }} src={icon} />

                <div className="col-md-6 mr-5">
                  <h1>{unitName}</h1>
                  <h4>Student History Report</h4>
                  <h5>Printing Date: {displayDate}</h5>
                </div>
              </div>
              <div className="container" style={{ marginTop: "20px" }}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div className="col-md-6 d-flex">
                    <h5 className="text-primary" style={{ fontSize: "18px" }}>
                      Admission No:-
                    </h5>
                    <h6 className="text-black" style={{ fontSize: "16px" }}>
                      &nbsp; &nbsp; {studentData?.admission_id}
                    </h6>
                  </div>
                  <div className="col-md-6 d-flex">
                    <h5 className="text-primary" style={{ fontSize: "18px" }}>
                      Class:-
                    </h5>
                    <h6 className="text-black" style={{ fontSize: "16px" }}>
                      &nbsp; &nbsp; {studentData?.student_class?.name}
                    </h6>
                  </div>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div className="col-md-6 d-flex">
                    <h5 className="text-primary" style={{ fontSize: "18px" }}>
                      Student Name:-
                    </h5>
                    <h6 className="text-black" style={{ fontSize: "16px" }}>
                      &nbsp; &nbsp; {studentData?.name}
                    </h6>
                  </div>
                  <div className="col-md-6 d-flex">
                    <h5 className="text-primary" style={{ fontSize: "18px" }}>
                      Section:-
                    </h5>
                    <h6 className="text-black" style={{ fontSize: "16px" }}>
                      &nbsp; &nbsp; {studentData?.global_section?.name}
                    </h6>
                  </div>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div className="col-md-6 d-flex">
                    <h5 className="text-primary" style={{ fontSize: "18px" }}>
                      Father Name:-
                    </h5>
                    <h6 className="text-black" style={{ fontSize: "16px" }}>
                      &nbsp; &nbsp; {studentData?.father_name}
                    </h6>
                  </div>
                  <div className="col-md-6 d-flex">
                    <h5 className="text-primary" style={{ fontSize: "18px" }}>
                      Session:-
                    </h5>
                    <h6 className="text-black" style={{ fontSize: "16px" }}>
                      &nbsp; &nbsp; {studentData?.session?.year}
                    </h6>
                  </div>
                </div>
              </div>
              <br />
              <div id="tableboot">
                <Table responsive>
                  <thead>
                    <tr id="tbl">
                      <th
                        className="text-center"
                        style={{ color: "black", fontSize: "16px" }}
                      >
                        SrNo.
                      </th>
                      <th
                        className="text-start"
                        style={{ color: "black", fontSize: "16px" }}
                      >
                        Title
                      </th>
                      <th
                        className="text-start"
                        style={{ color: "black", fontSize: "16px" }}
                      >
                        Description
                      </th>
                      <th
                        className="text-center"
                        style={{ color: "black", fontSize: "16px" }}
                      >
                        Date
                      </th>
                      <th
                        className="text-center"
                        style={{ color: "black", fontSize: "16px" }}
                      >
                        Action
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {historyData &&
                      historyData.map((item, index) => (
                        <tr id="tbl" key={index}>
                          <td
                            className="text-center text-black"
                            style={{
                              color: "black",
                              fontSize: "16px",
                              borderTop: "1px solid balck",
                              borderBottom: "1px solid balck",
                              padding: "1px",
                              whiteSpace: "normal",
                            }}
                          >
                            {index + 1}
                          </td>
                          <td
                            className="text-start text-black"
                            style={{
                              color: "black",
                              fontSize: "16px",
                              borderTop: "1px solid balck",
                              borderBottom: "1px solid balck",
                              padding: "1px",
                              whiteSpace: "normal",
                            }}
                          >
                            {item?.title}
                          </td>
                          <td
                            className="text-start text-black"
                            style={{
                              color: "black",
                              fontSize: "16px",
                              borderTop: "1px solid balck",
                              borderBottom: "1px solid balck",
                              padding: "1px",
                              whiteSpace: "normal",
                            }}
                          >
                            {item?.description}
                          </td>
                          <td
                            className="text-center text-black"
                            style={{
                              color: "black",
                              fontSize: "16px",
                              borderTop: "1px solid balck",
                              borderBottom: "1px solid balck",
                              padding: "1px",
                              whiteSpace: "normal",
                            }}
                          >
                            {item?.date}
                          </td>
                          <td
                            className="text-center text-black"
                            style={{
                              color: "black",
                              fontSize: "16px",
                              borderTop: "1px solid balck",
                              borderBottom: "1px solid balck",
                              padding: "1px",
                              whiteSpace: "normal",
                            }}
                          >
                            {item?.user?.first_name +
                              " " +
                              item?.user?.last_name}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </div>
            </>
          ) : null}
        </>
      </div>
    </>
  );
};

export default StudentHistory;
